/* istanbul ignore file */

module.exports = {
  corePlugins: {
    container: false,
  },
  content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}'],
  safelist: [
    'outline-none',
    {
      pattern: /flex-(col|col-reverse|row|row-reverse)/,
      variants: ['sm', 'md', 'lg'],
    },
  ],
  theme: {
    extend: {
      screens: {
        xs: '375px',
        sm: '480px',
        '3xl': '1920px',
      },
      fontSize: {
        xs: ['0.7rem', '1rem'],
        sm: ['0.875rem', '1rem'],
        base: ['1rem', '1.25rem'],
        lg: ['18px', '28px'],
        xl: ['22px', '27px'],
        '2xl': ['36px', '36px'],
        '4xl': ['96px', '97px'],
      },
      maxWidth: {
        '8xl': '1920px',
        '9xl': '96rem',
        '10xl': '104rem',
      },
      colors: {
        primary: 'var(--primary)',
        'primary-2': 'var(--primary-2)',
        secondary: 'var(--secondary)',
        'secondary-2': 'var(--secondary-2)',
        'light-gray': 'var(--light-gray)',
        cta: 'var(--cta)',
        contrasted: 'var(--contrasted)',
        'cta-light': 'var(--cta-light)',
        'hover-cta': 'var(--hover-cta)',
        error: 'var(--error)',
        selected: 'var(--selected)',
        disabled: 'var(--disabled)',
        'grey-100': 'var(--grey-100)',
        'grey-400': 'var(--grey-400)',
        'grey-600': 'var(--grey-600)',
        'grey-700': 'var(--grey-700)',
        'grey-800': 'var(--grey-800)',
        'red-100': 'var(--red-100)',
        'red-200': 'var(--red-200)',
        'red-300': 'var(--red-300)',
        'accents-0': 'var(--accents-0)',
        'accents-1': 'var(--accents-1)',
        'accents-2': 'var(--accents-2)',
        'accents-3': 'var(--accents-3)',
        'accents-4': 'var(--accents-4)',
        'accents-6': 'var(--accents-6)',
        'accents-7': 'var(--accents-7)',
        'accents-8': 'var(--accents-8)',
        'accents-9': 'var(--accents-9)',
        'accents-10': 'var(--accents-10)',
        'black-300': 'var(--black-300)',
        'black-500': 'var(--black-500)',
        'black-600': 'var(--black-600)',
        'green-200': 'var(--green-200)',
        cyan: 'var(--cyan)',
        blue: 'var(--blue)',
        'green-dark': 'var(--green-dark)',
        'outline-primary': 'var(--outline-primary)',
        'outline-selected': 'var(--outline-selected)',
        white: 'var(--white)',
        gold: 'var(--gold)',
        violet: 'var(--violet)',
        purple: 'var(--purple)',
        eggplant: 'var(--eggplant)',
        lilac: 'var(--lilac)',
        'lilac-30': 'var(--lilac-30)',
        sky: 'var(--sky)',
        throwback: 'var(--throwback)',
        mint: 'var(--mint)',
        midnight: 'var(--midnight)',
        caribbean: 'var(--caribbean)',
        evergreen: 'var(--evergreen)',
        protocol: 'var(--protocol)',
        orange: 'var(--orange)',
        orchid: 'var(--orchid)',
        olive: 'var(--olive)',
        clay: 'var(--clay)',
        burgundy: 'var(--burgundy)',
        bone: 'var(--bone)',
        'gray-50': 'var(--gray-50)',
        'gray-30': 'var(--gray-30)',
        'gray-20': 'var(--gray-20)',
        'gray-10': 'var(--gray-10)',
        'gray-5': 'var(--gray-5)',
        'yellow-100': 'var(--yellow-100)',
        'blue-600': 'var(--blue-600)',
        'light-blue-400': 'var(--light-blue-400)',
        'mintgreen-200': 'var(--mintgreen-200)',
        'tidepool-teal': 'var(--tidepool-teal)',
      },
      textColor: {
        base: 'var(--text-base)',
        primary: 'var(--text-primary)',
        secondary: 'var(--text-secondary)',
        'secondary-2': 'var(--text-secondary-2)',
        'secondary-3': 'var(--text-secondary-3)',
        disabled: 'var(--text-disabled)',
        placeholder: 'var(--text-placeholder)',
        cta: 'var(--cta)',
        'hover-cta': 'var(--hover-cta)',
        error: 'var(--error)',
        gold: 'var(--gold)',
        eggplant: 'var(--eggplant)',
        purple: 'var(--purple)',
        white: 'var(--white)',
        midnight: 'var(--midnight)',
        'blue-600': 'var(--blue-600)',
        'blue-900': 'var(--blue-900)',
      },
      boxShadow: {
        'outline-normal': '0 0 0 2px var(--accents-2)',
        magical: 'rgba(0, 0, 0, 0.02) 0px 30px 30px, rgba(0, 0, 0, 0.03) 0px 0px 8px, rgba(0, 0, 0, 0.05) 0px 1px 0px',
        navbar: 'rgba(0, 0, 0, 0.02) 0px 30px 30px, rgba(0, 0, 0, 0.03) 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 3px 2px',
        'inverse-magical':
          'rgba(0, 0, 0, 0.02) 0px -30px 30px, rgba(0, 0, 0, 0.03) 0px 0px 8px, rgba(0, 0, 0, 0.05) 0px -1px 0px',
        dropdown: 'rgb(0 0 0 / 6%) 0px 6px 6px, rgb(0 0 0 / 6%) 0px 4px 4px',
      },
      lineHeight: {
        'extra-loose': '2.2',
      },
      scale: {
        120: '1.2',
      },
      spacing: {
        xs: '8px',
        s: '12px',
        m: '16px',
        l: '20px',
        xl: '24px',
        xxl: '36px',
        '3xl': '48px',
        13: '3.25rem',
        15: '4.25rem',
        15.5: '4.325rem',
        18: '4.5rem',
        24: '5.75rem',
        60: '15rem',
      },
      rotate: {
        270: ['--tw-rotate', ' 270deg'],
      },
    },
  },
  plugins: [
    ({ addComponents }) => {
      addComponents({
        '.container': {
          '@apply mx-auto': {},
          width: '100%',
          maxWidth: '1440px',
        },
        '.container--oversized': {
          maxWidth: '1280px',
        },
      });
    },
  ],
};
