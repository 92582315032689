import FormData from 'form-data';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import mapValues from 'lodash/mapValues';
import toString from 'lodash/toString';
import type { Response } from 'node-fetch';
import { ParsedUrlQuery } from 'querystring';

import { GlobalQueryString } from '@components/ui/context';

export const getFormData = (data: Record<string, string | number | boolean>) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};

export function urlEncode(
  data: Record<string, string | string[] | number | boolean | undefined>,
  returnString?: false
): URLSearchParams;
export function urlEncode(
  data: Record<string, string | string[] | number | boolean | undefined>,
  returnString: true
): string;
export function urlEncode(
  data: Record<string, string | string[] | number | boolean | undefined>,
  returnString?: boolean
) {
  const params = new URLSearchParams(mapValues(data, toString));
  return returnString ? params.toString() : params;
}

export const getTextOrNull = (res: Response) => {
  try {
    return res.text();
  } catch {
    return null;
  }
};

export const getStringParam = (value: unknown): string | null => (isString(value) ? value : null);

export const stringifyArray = (arr?: string | (string | number)[]): string | undefined => {
  if (arr && arr instanceof Array) {
    return arr.join(',');
  }
  return arr;
};

export const stringifyObject = (fields?: Record<string, any> | string): string | undefined => {
  if (typeof fields !== 'string') {
    return !isEmpty(fields) ? JSON.stringify(fields) : undefined;
  }
  return fields;
};

export const getRedirectLocale = (query: ParsedUrlQuery, supportedLocales: string[], locale?: string) => {
  const targetLocale = getStringParam(query.targetlocale || query.targetLocale)?.replace(/-(.*)/, (str) =>
    str.toLocaleLowerCase()
  );

  if (targetLocale && supportedLocales.map((l) => l.toLocaleLowerCase()).includes(targetLocale.toLocaleLowerCase())) {
    return targetLocale;
  }

  return locale || '';
};

export const getLocalePath = (redirectLocale: string, defaultLocale?: string) => {
  return redirectLocale.toLocaleLowerCase() !== defaultLocale?.toLocaleLowerCase() ? `/${redirectLocale}` : '/';
};

export const cleanPath = (path: string) => path.replace(/\/\//g, '/');

export const cleanQueryParams = (query: ParsedUrlQuery) => {
  return [GlobalQueryString.IN_APP, GlobalQueryString.REFERRER].reduce(
    (acc: any, key) => {
      if (query[key]) {
        acc[key] = query[key];
      }
      return acc;
    },
    {} as Record<string, string>
  );
};
