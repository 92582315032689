export type Options = {
  session?: boolean;
  manualTrigger?: boolean;
};

export function setStorage(key: string, value: string | object, options?: Options) {
  if (window) {
    const { session = true, manualTrigger = false } = options || {};
    const storage = session ? window.sessionStorage : window.localStorage;

    if (!storage) {
      return;
    }

    storage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));

    if (manualTrigger) {
      triggerEvent();
    }
  }
}

export function getStorage<T = any>(key: string, options?: Options): T | string | null {
  if (window) {
    const { session = true } = options || {};

    const storage = session ? window.sessionStorage : window.localStorage;

    const value = storage?.getItem?.(key);
    if (value) {
      try {
        const parsed = JSON.parse(value);
        return parsed as T;
      } catch {
        return value;
      }
    }
  }
  return null;
}

export function removeStorage(key: string, options?: Options) {
  if (window) {
    const { session = true } = options || {};
    const storage = session ? window.sessionStorage : window.localStorage;

    storage?.removeItem(key);
  }
}

// manually dispatch event to trigger listener within the same tab
export function triggerEvent() {
  if (window) {
    window.dispatchEvent(new Event('storage'));
  }
}
