export function isStorefrontDisabled() {
  return process.env.NEXT_PUBLIC_DISABLE_STOREFRONT === 'true';
}

export function isLife360Enabled() {
  return [process.env.NEXT_PUBLIC_IS_LIFE360, process.env.NEXT_PUBLIC_IS_LIFE360_INTL].some((val) => val === 'true');
}

export function isLife360IntlSite() {
  return process.env.NEXT_PUBLIC_IS_LIFE360_INTL === 'true';
}
