import getConfig from 'next/config';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { i18n } from 'next-i18next.config';
import {
  ArticleJsonLd,
  BreadcrumbJsonLd,
  DefaultSeo,
  DefaultSeoProps,
  LogoJsonLd,
  NextSeo,
  SiteLinksSearchBoxJsonLd,
} from 'next-seo';
import { ItemListElements } from 'next-seo/lib/types';
import { FC } from 'react';

import config from '@config/seo.json';
import { isLife360Enabled } from '@lib/storefront';

import { getBreadcrumbsItems, getSeoUrlConfig, shouldSkipRootSeo } from './helper';

interface Props {
  moduleSeo?: any;
  isErrorPage?: boolean;
}

const Head: FC<Props> = ({ moduleSeo, isErrorPage }: Props) => {
  const { locale = i18n.defaultLocale, asPath, pathname, locales = i18n.locales } = useRouter();
  const {
    publicRuntimeConfig: { siteUrl },
  } = getConfig();
  const baseUrl = `${siteUrl}/${locale}`;
  // moduleSeo content can be returned within `content` field (when includeContentTypeInFields: true) or at root
  // hence this check
  const seo = moduleSeo?.content || moduleSeo;

  const breadcrumbsItems: ItemListElements[] = seo?.breadcrumbs
    ? getBreadcrumbsItems([{ name: 'Tile', url: baseUrl }, ...seo.breadcrumbs])
    : [];

  const selectedConfig = isLife360Enabled() ? config.l360 : config.tile;
  const seoConfig: DefaultSeoProps = {
    ...selectedConfig,
    ...(seo || {}),
    ...getSeoUrlConfig({
      origin: siteUrl,
      pathname: asPath,
      currentLocale: locale,
      resourceAlternateSummary: moduleSeo.resourceAlternateSummary ?? null,
      locales: [...locales],
      is404: isErrorPage,
    }),
  };
  seoConfig.openGraph = seoConfig.openGraph
    ? {
        ...seoConfig.openGraph,
        title: seoConfig.title,
        description: seoConfig.description,
        locale,
      }
    : seoConfig.openGraph;

  const renderSeo = () => (seo?.noRobots ? <NextSeo {...seoConfig} noindex /> : <DefaultSeo {...seoConfig} />);

  return (
    <>
      {!shouldSkipRootSeo(pathname) && renderSeo()}
      <LogoJsonLd logo={`${siteUrl}/logo.jpg`} url={siteUrl} />
      <SiteLinksSearchBoxJsonLd
        url={siteUrl}
        potentialActions={[
          {
            target: `${baseUrl}/products?q`,
            queryInput: 'search',
          },
        ]}
      />
      {breadcrumbsItems.length > 0 && <BreadcrumbJsonLd itemListElements={breadcrumbsItems} />}
      {seo?.article && <ArticleJsonLd {...seo.article} url={`${baseUrl}${seo.article.url}`} />}
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
      </NextHead>
    </>
  );
};
export default Head;
