import { getOsanoClientConfig } from '@lib/get-client-config';
import { pushToGTMDataLayer } from '@lib/gtm';
import { isLife360Enabled } from '@lib/storefront';

import { OsanoConsentObject } from './types';

// Default consent values (in case no Osano consent is available)
const DEFAULT_CONSENT = {
  ESSENTIAL: 'ACCEPT',
  ANALYTICs: 'DENY',
  MARKETING: 'DENY',
  PERSONALIZATION: 'DENY',
  STORAGE: 'DENY',
  OPT_OUT: 'DENY',
};

// GTM event name for Osano consent categories information
const OSANO_TO_DATA_LAYER_EVENT = 'OsanoConsentCategoriesUpdated';

// Map Osano consent categories to Data Layer custom consent categories for GTM
enum DataLayerConsentMap {
  MARKETING = 'OsanoConsentCategoryMarketing',
  ANALYTICS = 'OsanoConsentCategoryAnalytics',
  ESSENTIAL = 'OsanoConsentCategoryEssential',
  PERSONALIZATION = 'OsanoConsentCategoryPersonalization',
  STORAGE = 'OsanoConsentCategoryStorage',
  OPT_OUT = 'OsanoConsentCategoryOptOut',
}

// Osano <script> element ID
export const osanoScriptElementId = 'osano-cmp-script';
const osanoConfig = isLife360Enabled() ? getOsanoClientConfig().life360 : getOsanoClientConfig().tile;
// Osano script file URl
export const osanoScriptLink =
  osanoConfig?.configurationId && osanoConfig?.customerId
    ? `https://cmp.osano.com/${osanoConfig.customerId}/${osanoConfig.configurationId}/osano.js`
    : null;

/**
 * Interpret Osano consent object and map it to Data Layer custom consent categories.
 * This is needed to avoid naming conflicts between Osano and GTM Data Layer.
 */
const interpretConsent = (osanoConsent: OsanoConsentObject) => {
  const consent: OsanoConsentObject = {};

  for (const category in osanoConsent) {
    if (Object.prototype.hasOwnProperty.call(osanoConsent, category)) {
      const acceptOrDeny = osanoConsent[category];
      const consentKey = DataLayerConsentMap[`${category}`.toUpperCase() as keyof typeof DataLayerConsentMap];
      if (consentKey && consentKey.length) {
        consent[consentKey as keyof OsanoConsentObject] = `${acceptOrDeny}`.toUpperCase();
      }
    }
  }

  return consent;
};

/**
 * Create Osano event listeners for consent manager initialization and consent saved events.
 */
export const createOsanoEventListeners = () => {
  // Event listener for consent manager initialization.
  // Uses to notify GTM via Data Layer about consent categories and their values
  window.Osano?.cm?.addEventListener('osano-cm-initialized', (consent) => {
    pushToGTMDataLayer({ event: OSANO_TO_DATA_LAYER_EVENT, ...interpretConsent(consent as OsanoConsentObject) });
  });

  // Event listener for consent saved (most likely user changed consent and saved)
  window.Osano?.cm?.addEventListener('osano-cm-consent-saved', (consent) => {
    pushToGTMDataLayer({ event: OSANO_TO_DATA_LAYER_EVENT, ...interpretConsent(consent as OsanoConsentObject) });
  });
};

/**
 * Push default consent values to Data Layer in case Osano consent is not available.
 */
export const pushDefaultConsentToDataLayer = () => {
  pushToGTMDataLayer({ event: OSANO_TO_DATA_LAYER_EVENT, ...interpretConsent(DEFAULT_CONSENT as OsanoConsentObject) });
};
