import { ThemeProvider } from 'next-themes';
import React, { useCallback, useMemo } from 'react';

import { Product } from '@commerce/types/product';
import { GlobalParams, useGlobalParams } from '@components/ui/useGlobalParams';
import { FCWithChildren } from '@lib/types/react-utilities';

import type { Modal, ModalViews } from './modals';

type LinkObj = { title: string; href: string };
type Submenu = {
  title: string;
  links: Array<LinkObj>;
};

export enum GlobalQueryString {
  IN_APP = 'inapp',
  AUTH_CODE = 'code',
  TOKEN = 'token',
  MODAL = 'modal',
  NEXT = 'next',
  REFERRER = 'referrer',
  USER_LOCALE = 'userLocale',
  VERIFIED = 'verified',
  LANG = 'lang',
  REDIRECT = 'redirect',
}

interface State {
  displayCartOverlay: boolean;
  displayDropdown: boolean;
  displayModal: boolean;
  displayToast: boolean;
  activeSubmenu?: Submenu;
  isMenuActive: boolean;
  isDownloadPage: boolean;
  toastText: string;
  userAvatar: string;
  modal: Modal;
  contentOffsetTop: number;
  product: Product | null;
}

interface Actions {
  openCartOverlay: () => void;
  closeCartOverlay: () => void;
  openDropdown: () => void;
  closeDropdown: () => void;
  openModal: () => void;
  closeModal: () => void;
  setModal: (view: ModalViews, data?: Record<string, any>, className?: string, hideCloseBtn?: boolean) => void;
  openToast: () => void;
  closeToast: () => void;
  setUserAvatar: (value: string) => void;
  setContentOffsetTop: (value: number) => void;
  setIsDownloadPage: (value: boolean) => void;
  setProductPage: (p: Product | null) => void;
}

interface Context extends Actions, State {
  globalParams: GlobalParams;
}

const initialModal: Modal = { view: 'PASSWORD_LOGIN_VIEW' };

const initialState: State = {
  displayCartOverlay: false,
  displayDropdown: false,
  displayModal: false,
  activeSubmenu: undefined,
  isMenuActive: false,
  displayToast: false,
  isDownloadPage: false,
  toastText: '',
  userAvatar: '',
  modal: initialModal,
  contentOffsetTop: 0,
  product: null,
};

const defaultFn = () => {};
const initialActions: Actions = {
  openCartOverlay: defaultFn,
  closeCartOverlay: defaultFn,
  openDropdown: defaultFn,
  closeDropdown: defaultFn,
  openModal: defaultFn,
  closeModal: defaultFn,
  setModal: defaultFn,
  openToast: defaultFn,
  closeToast: defaultFn,
  setUserAvatar: defaultFn,
  setContentOffsetTop: defaultFn,
  setIsDownloadPage: defaultFn,
  setProductPage: defaultFn,
};

type Action =
  | {
      type: 'OPEN_CART_OVERLAY';
    }
  | {
      type: 'CLOSE_CART_OVERLAY';
    }
  | {
      type: 'OPEN_TOAST';
    }
  | {
      type: 'CLOSE_TOAST';
    }
  | {
      type: 'SET_TOAST_TEXT';
      text: ToastText;
    }
  | {
      type: 'OPEN_DROPDOWN';
    }
  | {
      type: 'CLOSE_DROPDOWN';
    }
  | {
      type: 'OPEN_MODAL';
    }
  | {
      type: 'CLOSE_MODAL';
    }
  | {
      type: 'SET_MODAL';
      view: ModalViews;
      data?: Record<string, any>;
      className?: string;
      hideCloseBtn?: boolean;
    }
  | {
      type: 'SET_USER_AVATAR';
      value: string;
    }
  | {
      type: 'SET_CONTENT_OFFSET_TOP';
      value: number;
    }
  | {
      type: 'SET_IS_DOWNLOAD_PAGE';
      value: boolean;
    }
  | {
      type: 'SET_PRODUCT_PAGE';
      value: Product | null;
    };

type ToastText = string;

export const UIContext = React.createContext<Context>({ ...initialState, ...initialActions, globalParams: {} });

UIContext.displayName = 'UIContext';

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_CART_OVERLAY': {
      return {
        ...state,
        displayCartOverlay: true,
      };
    }
    case 'CLOSE_CART_OVERLAY': {
      return {
        ...state,
        displayCartOverlay: false,
      };
    }
    case 'OPEN_DROPDOWN': {
      return {
        ...state,
        displayDropdown: true,
      };
    }
    case 'CLOSE_DROPDOWN': {
      return {
        ...state,
        displayDropdown: false,
      };
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      };
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        displayModal: false,
        modal: initialModal,
      };
    }
    case 'OPEN_TOAST': {
      return {
        ...state,
        displayToast: true,
      };
    }
    case 'CLOSE_TOAST': {
      return {
        ...state,
        displayToast: false,
      };
    }
    case 'SET_MODAL': {
      return {
        ...state,
        modal: {
          view: action.view,
          data: action.data,
          className: action.className,
          hideCloseBtn: action.hideCloseBtn,
        },
      };
    }
    case 'SET_TOAST_TEXT': {
      return {
        ...state,
        toastText: action.text,
      };
    }
    case 'SET_USER_AVATAR': {
      return {
        ...state,
        userAvatar: action.value,
      };
    }
    case 'SET_CONTENT_OFFSET_TOP': {
      return {
        ...state,
        contentOffsetTop: action.value,
      };
    }
    case 'SET_IS_DOWNLOAD_PAGE': {
      return {
        ...state,
        isDownloadPage: action.value,
      };
    }
    case 'SET_PRODUCT_PAGE': {
      return {
        ...state,
        product: action.value,
      };
    }
    default:
      return { ...state };
  }
}

export const UIProvider: FCWithChildren<{ product: Product | null }> = ({ children, product: initialProduct }) => {
  const [state, dispatch] = React.useReducer(uiReducer, { ...initialState, product: initialProduct });
  const globalParams = useGlobalParams();

  const openCartOverlay = useCallback(() => dispatch({ type: 'OPEN_CART_OVERLAY' }), [dispatch]);
  const closeCartOverlay = useCallback(() => dispatch({ type: 'CLOSE_CART_OVERLAY' }), [dispatch]);

  const openDropdown = useCallback(() => dispatch({ type: 'OPEN_DROPDOWN' }), [dispatch]);
  const closeDropdown = useCallback(() => dispatch({ type: 'CLOSE_DROPDOWN' }), [dispatch]);

  const openModal = useCallback(() => dispatch({ type: 'OPEN_MODAL' }), [dispatch]);
  const closeModal = useCallback(() => dispatch({ type: 'CLOSE_MODAL' }), [dispatch]);

  const openToast = useCallback(() => dispatch({ type: 'OPEN_TOAST' }), [dispatch]);
  const closeToast = useCallback(() => dispatch({ type: 'CLOSE_TOAST' }), [dispatch]);

  const setUserAvatar = useCallback((value: string) => dispatch({ type: 'SET_USER_AVATAR', value }), [dispatch]);

  const setIsDownloadPage = useCallback(
    (value: boolean) => dispatch({ type: 'SET_IS_DOWNLOAD_PAGE', value }),
    [dispatch]
  );

  // refactor the args to be an obj instead?
  const setModal = useCallback(
    (view: ModalViews, data?: Record<string, any>, className?: string, hideCloseBtn?: boolean) => {
      const hideClose = view === 'LOADING_VIEW' && typeof hideCloseBtn === 'undefined' ? true : !!hideCloseBtn;
      dispatch({ type: 'SET_MODAL', view, data, className, hideCloseBtn: hideClose });
    },
    [dispatch]
  );

  const setContentOffsetTop = useCallback(
    (value: number) => dispatch({ type: 'SET_CONTENT_OFFSET_TOP', value }),
    [dispatch]
  );

  const setProductPage = useCallback(
    (product: Product | null) => dispatch({ type: 'SET_PRODUCT_PAGE', value: product }),
    [dispatch]
  );

  const actions: Actions = {
    openCartOverlay,
    closeCartOverlay,
    openDropdown,
    closeDropdown,
    openModal,
    closeModal,
    setModal,
    openToast,
    closeToast,
    setUserAvatar,
    setContentOffsetTop,
    setIsDownloadPage,
    setProductPage,
  };

  const value = useMemo(
    () => ({
      ...state,
      ...actions,
      globalParams,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, globalParams]
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUI = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error('useUI must be used within a UIProvider');
  }
  return context;
};

export const ManagedUIContext: FCWithChildren<{ product: Product | null }> = ({ product, children }) => (
  <UIProvider product={product}>
    <ThemeProvider>{children}</ThemeProvider>
  </UIProvider>
);
