import Contentful from '@services/contentful';
import { Entry } from '@services/contentful/types';
import isEmpty from 'lodash/isEmpty';

import type { ModuleBlog } from '@components/common/types/ModuleBlog';
import type { ModuleCategory } from '@components/common/types/ModuleCategory';
import type { ModuleFeaturedPartner } from '@components/common/types/ModuleFeaturedPartner';
import type { ModuleProduct } from '@components/common/types/ModuleProduct';
import { PageContent } from '@components/common/types/Page';
import contentfulToStorefrontMapping from '@config/contentful-to-storefront-mapping.json';
import storefrontHtmlLangMapping from '@config/storefront-html-lang-mapping.json';
import { isDefaultLocaleIgnoreCase, originalLocaleFormat } from '@lib/locales';

import { isLife360IntlSite } from './storefront';

/**
 * when we fetch * locales from Contentful it maps localized content under the property keys
 * @example
 * { url: string }             // locale: 'en-US'
 * { url: { 'en-US': string }} // with { getAllLocales: true } in config
 */
type Localized<T extends object = Record<string, unknown>> = {
  [K in keyof T]: Record<string, T[K]>;
};

type EntryContent<T> = Pick<Entry<T>, 'content' | 'updatedAt' | 'id'>;

/**
 * @example
 * { url: { 'en-US': 'top-things' }, title: { 'en-AU': 'Top Travel Things','en-US': 'Top Travel Things' }}
 */
type OnlyBlogIdentifiers = Pick<ModuleBlog, 'title' | 'url'>;
type OnlyPartnerIdentifiers = Pick<ModuleFeaturedPartner, 'slug' | 'partner'>;
type OnlyGenericScreenIdentifiers = Pick<PageContent, 'pageLink' | 'contentModules'>;
type OnlyProductIdentifiers = Pick<ModuleProduct, 'name' | 'url' | 'bigCommerceId' | 'sku'>;
type OnlyPlpScreenIdentifiers = {
  updatedAt: string;
  id: string;
  visibleInLocale: { [key: string]: string };
};

export type PathAlternatesSummary = {
  updatedAt: string;
  localesWithContent: string[];
  path: string;
  id: string;
};

export type ProductPathAlternatesSummaryWithSku = PathAlternatesSummary & { sku: string };

type ContentfulPathAlternatesSummariesFn<T = PathAlternatesSummary> = (
  c: Contentful,
  locales: string[],
  query?: Record<string, string | string[] | number>
) => Promise<T[]>;

/**
 * Must have non-empty values assigned to each key
 */
export const guardEntryHasRequiredKeys =
  <T extends Localized>(...keys: Array<keyof T>) =>
  (e: EntryContent<T>): e is EntryContent<Required<T>> => {
    return e && e.content && keys.every((k) => !isEmpty(e.content[k]));
  };

const guardNil = <T>(value: T | null | undefined): value is T => {
  return value !== null && value !== undefined;
};

/**
 * Return the locales that have content for the given key of the given entry.
 *
 * @example
 * getLocalesWithContent(['en-us', 'en-au'], {'en})
 */
export const getLocalesWithContent = (locales: string[], mayHaveContent: Record<string, any>) => {
  const lowercaseKeys = Object.entries(mayHaveContent)
    .filter(([, value]) => guardNil(value))
    .map(([l]) => l.toLocaleLowerCase());

  const lowercaseLocales = locales.map((l) => l.toLocaleLowerCase());
  return lowercaseLocales.filter((l) => lowercaseKeys.includes(l));
};

/**
 *
 * @param locales
 * @param contentCheckKey locales must have content in this entry property
 * @param pathKey use this key to pull the resource's path
 * @param getPath fn to build the resource path
 */
export const mapEntry =
  <T extends Localized>(
    locales: string[],
    contentCheckKey: keyof T,
    pathKey: keyof T,
    getPath: (p: string) => string,
    checkOtherLocalesForPath?: boolean
  ) =>
  (e: EntryContent<T>): PathAlternatesSummary | null => {
    let path = e.content[pathKey]['en-US'];

    if (!path && e.content[pathKey] && checkOtherLocalesForPath) {
      [path] = Object.values(e.content[pathKey]);
    }
    // we can exclude all external URLs
    if (!path || typeof path !== 'string' || path.startsWith('http')) {
      return null;
    }

    const trimmedPath = path
      .replace(/^\/*/, '') // remove starting slash
      .replace(/\/+$/, ''); // remove trailing slash

    const localesWithContent = getLocalesWithContent(locales, e.content[contentCheckKey]);

    return {
      id: e.id,
      updatedAt: e.updatedAt,
      localesWithContent,
      path: getPath(trimmedPath).replace(/\/\//, ''),
    };
  };

export const getPartnerPathAlternateSummaries: ContentfulPathAlternatesSummariesFn = async (
  contentful,
  locales,
  query
): Promise<PathAlternatesSummary[]> => {
  const partners = await contentful
    .search<Localized<OnlyPartnerIdentifiers>>(
      {
        limit: 1000,
        ...query,
        content_type: 'moduleFeaturedPartner',
        select: ['sys.id', 'sys.updatedAt', 'fields.slug', 'fields.partner'],
      },
      {
        getAllLocales: true,
      }
    )
    .then((res) => res?.entries ?? []);

  return partners
    .filter(guardEntryHasRequiredKeys('slug', 'partner'))
    .map(mapEntry(locales, 'partner', 'slug', (p) => `/partner/${p}`))
    .filter(guardNil);
};

export const getScreenPathAlternatesSummaries: ContentfulPathAlternatesSummariesFn = async (
  contentful,
  locales,
  query
) => {
  const screens = await contentful
    .search<Localized<OnlyGenericScreenIdentifiers>>(
      {
        ...query,
        content_type: 'screen',
        select: ['sys.id', 'sys.updatedAt', 'fields.pageLink', 'fields.contentModules'],
      },
      {
        getAllLocales: true,
      }
    )
    .then((res) => res?.entries ?? []);

  return screens
    .filter(guardEntryHasRequiredKeys('pageLink', 'contentModules'))
    .map(mapEntry(locales, 'contentModules', 'pageLink', (p) => (p === '__home__' ? '' : `/${p}`)))
    .filter(guardNil);
};

export const getBlogPathAlternateSummaries: ContentfulPathAlternatesSummariesFn = async (
  contentful,
  locales,
  query
) => {
  const blogs = await contentful
    .search<Localized<OnlyBlogIdentifiers>>(
      {
        limit: 1000,
        ...query,
        content_type: 'moduleBlog',
        select: ['sys.id', 'sys.updatedAt', 'fields.url', 'fields.title'],
      },
      { getAllLocales: true }
    )
    .then((res) => res?.entries ?? []);

  return blogs
    .filter(guardEntryHasRequiredKeys('url', 'title'))
    .map(mapEntry(locales, 'title', 'url', (p) => `/blog/${p}`))
    .filter(guardNil);
};

export const getBlogCategoryPathAlternateSummaries: ContentfulPathAlternatesSummariesFn = async (
  contentful,
  locales,
  query
) => {
  const categories = await contentful
    .search<Localized<ModuleCategory>>(
      {
        limit: 1000,
        ...query,
        content_type: 'moduleBlogCategory',
        select: ['sys.id', 'sys.updatedAt', 'fields.url', 'fields.title'],
      },
      { getAllLocales: true }
    )
    .then((res) => res?.entries ?? []);

  // edge case because we have to do a reverse lookup on blog model's blog categories
  // for each blog category, find a single blog post for each possible locale that satisfies blog existence criteria
  // blog post must have localized content
  const categoriesWithBlogs = await Promise.all(
    categories.flatMap((c) =>
      locales.map((l) =>
        contentful
          .findOne(
            {
              select: ['fields.internalName', 'fields.title', 'fields.url'],
              content_type: 'moduleBlog',
              'fields.title[exists]': true,
              'fields.url[exists]': true,
              'fields.moduleBlogCategory.sys.id': c.id,
              'fields.moduleBlogCategory.sys.contentType.sys.id': 'moduleBlogCategory',
            },
            { locale: originalLocaleFormat(l) }
          )
          .then((res) => ({
            locale: l,
            exists: !!res,
            id: c.id,
          }))
      )
    )
  ).then((res) => res.filter((item) => item.exists));

  const categoriesWithBlogsMap = categoriesWithBlogs.reduce<Map<string, Set<string>>>((acc, curr) => {
    let set = acc.get(curr.id);
    if (set) {
      set.add(curr.locale);
    } else {
      set = new Set([curr.locale]);
      acc.set(curr.id, set);
    }
    return acc;
  }, new Map());

  return categories
    .filter(guardEntryHasRequiredKeys('url', 'title'))
    .map(mapEntry(locales, 'title', 'url', (p) => `/blog/category/${p}`))
    .filter(guardNil)
    .map((summary) => {
      const localesWithContentSet = categoriesWithBlogsMap.get(summary.id);
      return {
        ...summary,
        localesWithContent: summary.localesWithContent.filter((l) => localesWithContentSet?.has(l)),
      };
    });
};

export const getProductPathAlternateSummaries: ContentfulPathAlternatesSummariesFn<
  ProductPathAlternatesSummaryWithSku
> = async (contentful, locales, query) => {
  const products = await contentful
    .search<Localized<OnlyProductIdentifiers>>(
      {
        limit: 1000,
        ...query,
        content_type: 'product',
        select: ['sys.id', 'sys.updatedAt', 'fields.name', 'fields.url', 'fields.bigCommerceId', 'fields.sku'],
      },
      { getAllLocales: true }
    )
    .then((res) => res?.entries ?? []);

  const guardedProducts = products.filter(guardEntryHasRequiredKeys('url', 'name', 'bigCommerceId'));
  const entries = guardedProducts.map(mapEntry(locales, 'bigCommerceId', 'url', (p) => p, true)); // it is already the prefixed path in contentful '/product/PRODUCT_URL'
  return entries.filter(guardNil).map((entry) => ({
    ...entry,
    sku: guardedProducts.find((p) => p.id === entry.id)?.content.sku['en-US'] ?? '',
  }));
};

export const getPlpLocalesWithContent: any = async (contentful: Contentful, query: any) => {
  const screenPlp = await contentful.findOne<OnlyPlpScreenIdentifiers>(
    {
      limit: 1,
      ...query,
      content_type: 'screenPlp',
      select: ['sys.id', 'sys.updatedAt', 'fields.visibleInLocale'],
    },
    { getAllLocales: true }
  );

  const localesWithContent: string[] = [];
  if (screenPlp?.content.visibleInLocale) {
    Object.entries(screenPlp.content.visibleInLocale).forEach(([localeKey, isVisible]) => {
      if (isVisible) {
        localesWithContent.push(contentfulToStorefrontMapping[localeKey as keyof typeof contentfulToStorefrontMapping]);
      }
    });
  }

  return { updatedAt: screenPlp?.updatedAt, localesWithContent, id: screenPlp?.id };
};

export const buildLocalizedUrl = ({ origin, locale, path }: { origin: string; locale: string; path: string }) => {
  const l = locale.toLocaleLowerCase();
  const localizedPath = `${l}/${path}`
    .replace(/\/$/, '') // remove trailing slash
    .replace(/\/\//, '/'); // remove double slash
  const url = new URL(localizedPath, origin.replace(/\/$/, '')); // remove trailing slash

  // clear other properties and enforce lowercase
  url.search = '';
  url.hash = '';
  url.pathname = url.pathname.toLocaleLowerCase();

  return url.toString().replace(/\/$/, '');
};

/**
 *
 * @param localesWithContent
 * @param path
 * @param currentLocale building alternates for this locale
 */
export const buildAlternateReferences = (
  origin: string,
  { localesWithContent = [], path }: Omit<PathAlternatesSummary, 'updatedAt' | 'id'>
) => {
  const locales = localesWithContent.filter((el) => el).map((l) => l.toLocaleLowerCase());
  const hasUsLocale = !!locales.find((l) => isDefaultLocaleIgnoreCase(l));

  const altReferences = locales
    .map((l) => l.toLocaleLowerCase())
    .map((l) => {
      return {
        href: buildLocalizedUrl({ origin, locale: l, path }),
        hrefLang: storefrontHtmlLangMapping[l] ?? l,
      };
    });
  const nonPrefixedUrl = new URL(path, origin.replace(/\/$/, '')).toString().replace(/\/$/, '');
  if (hasUsLocale) {
    altReferences.push({
      href: nonPrefixedUrl,
      hrefLang: 'x-default',
    });
  }

  // for international Life360 en-us not required
  if (isLife360IntlSite()) {
    return [
      {
        href: nonPrefixedUrl,
        hrefLang: 'x-default',
      },
    ];
  }

  return altReferences;
};
