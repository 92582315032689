import { CF_USER_COUNTRY } from '@constants/cloudflare';
import Cookies from 'js-cookie';

export const SUPPORTED_LOCALES = {
  EN_US: 'en-us',
  EN_CA: 'en-ca',
  FR_CA: 'fr-ca',
  EN_AU: 'en-au',
  EN_NZ: 'en-nz',
  EN_GB: 'en-gb',
  // DE_EU: 'de-eu',
  EN_EU: 'en-eu',
  // ES_EU: 'es-eu',
  // FR_EU: 'fr-eu',
  // IT_EU: 'it-eu',
  // NL_EU: 'nl-eu',
  EN_IE: 'en-ie',
  EN_FR: 'en-fr',
  EN_DE: 'en-de',
  EN_AT: 'en-at',
  EN_BE: 'en-be',
  EN_FI: 'en-fi',
  EN_CH: 'en-ch',
  EN_NL: 'en-nl',
  NL_NL: 'nl-nl',
  // SV_SE: 'sv-se',
  // FI_FI: 'fi-fi',
  // NO_NO: 'no-no',
  // DA_DK: 'da-dk',
  // DE_DE: 'de-de',
} as const;

// update this list when more EMEA locales are added
export const EMEA_LOCALES: string[] = [
  // SUPPORTED_LOCALES.DE_EU,
  SUPPORTED_LOCALES.EN_EU,
  // SUPPORTED_LOCALES.ES_EU,
  // SUPPORTED_LOCALES.FR_EU,
  // SUPPORTED_LOCALES.IT_EU,
  // SUPPORTED_LOCALES.NL_EU,
  SUPPORTED_LOCALES.EN_GB,
  SUPPORTED_LOCALES.EN_IE,
  SUPPORTED_LOCALES.EN_FR,
  SUPPORTED_LOCALES.EN_DE,
  SUPPORTED_LOCALES.EN_AT,
  SUPPORTED_LOCALES.EN_BE,
  SUPPORTED_LOCALES.EN_FI,
  SUPPORTED_LOCALES.EN_CH,
  SUPPORTED_LOCALES.EN_NL,
  SUPPORTED_LOCALES.NL_NL,
  // SUPPORTED_LOCALES.SV_SE,
  // SUPPORTED_LOCALES.FI_FI,
  // SUPPORTED_LOCALES.NO_NO,
  // SUPPORTED_LOCALES.DA_DK,
  // SUPPORTED_LOCALES.DE_DE,
];

export const isUSLocale = (locale?: string) => locale === SUPPORTED_LOCALES.EN_US;
export const isGBLocale = (locale?: string) => locale === SUPPORTED_LOCALES.EN_GB;
export const isCALocale = (locale?: string) => locale === SUPPORTED_LOCALES.EN_CA || locale === SUPPORTED_LOCALES.FR_CA;
export const isAULocale = (locale?: string) => locale === SUPPORTED_LOCALES.EN_AU;
export const isNZLocale = (locale?: string) => locale === SUPPORTED_LOCALES.EN_NZ;
export const isDefaultLocaleIgnoreCase = (locale?: string) => locale?.toLocaleLowerCase() === SUPPORTED_LOCALES.EN_US;

/**
 * We originally had uppercase locale and some services still require this (ex. Tile Services)
 * ex: en-CA, en-NZ
 *
 * This doesn't validate whether the locale is a supported locale
 */
export const originalLocaleFormat = (locale?: string) =>
  locale?.replace(/-(.*)/, (str) => str.toLocaleUpperCase()) ?? '';

/**
 * get site locale from original locale key
 * @param originalLocale
 * @returns
 */
export const siteLocaleFormat = (originalLocale?: string) =>
  originalLocale?.replace(/-(.*)/, (str) => str.toLocaleLowerCase()) ?? '';

/**
 * to translate locale to contentful locale prefix within internal name
 * @param locale
 */
export const getContentfulContentLocale = (locale: string): string => {
  if (locale.toLowerCase().endsWith('ca')) {
    return locale.replace(/-/gi, '').toUpperCase();
  }

  return locale.split('-')?.[1]?.toUpperCase() || '';
};

export const isEmeaLocale = (locale: string): boolean => {
  return EMEA_LOCALES.includes(locale);
};

export const getCountryCode = (locale: string, lowerCase = false): string => {
  const countryFromCookies = Cookies.get(CF_USER_COUNTRY);
  const countryCodeFromLocale = locale.split('-')?.[1]?.toLowerCase() || '';
  const parsedCountryCode =
    !!countryCodeFromLocale && countryCodeFromLocale !== 'eu' ? countryCodeFromLocale : countryFromCookies || 'us';
  return lowerCase ? parsedCountryCode.toLowerCase() : parsedCountryCode.toUpperCase();
};
