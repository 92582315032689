import { useRouter } from 'next/router';
import { i18n } from 'next-i18next.config';
import { encode, ParsedUrlQuery } from 'querystring';
import { useEffect, useMemo } from 'react';

import { GlobalQueryString } from '@components/ui/context';
import { getLanguages } from '@lib/get-languages';
import { getStringParam } from '@lib/http';

export type GlobalParams = {
  inapp?: number;
  redirect?: string;
  lang?: string;
};

const SUPPORTED_REDIRECT_HOSTS = ['tile.com', 'thetileapp.jp'];
/**
 * We only want the language component of locales.
 */
const SUPPORTED_LANGS = new Set([
  ...i18n.locales,
  ...getLanguages(),
  'ja',
  'fr-ca', // TODO: remove hardcoded list from this line up to the end once we have it added to i18next config
  'en-gb',
  'en-de',
  'de-de',
  'en-ie',
  'en-fr',
  'fr-fr',
  'en-nd',
  'da-nd',
  'fi-nd',
  'no-nd',
  'sv-nd',
  'en-eu',
  'de-eu',
  'nl-eu',
  'fr-eu',
  'it-eu',
  'es-eu',
]);

export const validateLang = (query: ParsedUrlQuery) => {
  const lang = getStringParam(query[GlobalQueryString.LANG])?.toLocaleLowerCase();

  if (typeof lang === 'string') {
    const language = SUPPORTED_LANGS.has(lang) ? lang : undefined;
    return language;
  }

  return undefined;
};

const validateRedirect = (query: ParsedUrlQuery) => {
  const redirect = getStringParam(query[GlobalQueryString.REDIRECT]);
  if (typeof redirect !== 'string') {
    return undefined;
  }

  try {
    const url = new URL(redirect);

    // apparently \. doesn't need escaped? but it looks crazy to me otherwise so I'm disabling for this line
    // eslint-disable-next-line no-useless-escape
    if (!SUPPORTED_REDIRECT_HOSTS.some((h) => url.hostname.match(new RegExp(`(.*\.)*?${h}`)))) {
      throw new Error(`Unsupported redirect hostname: ${url.hostname}`);
    }

    return redirect;
  } catch {
    return undefined;
  }
};

const validateInApp = (query: ParsedUrlQuery) => {
  const inapp = Number(query[GlobalQueryString.IN_APP]);

  if (inapp === 1) {
    return inapp;
  }

  return undefined;
};

export const useGlobalParams = (): GlobalParams => {
  const router = useRouter();

  const inapp = validateInApp(router.query);
  const lang = validateLang(router.query);
  const redirect = validateRedirect(router.query);

  const params = useMemo(() => ({ inapp, redirect, lang }), [inapp, redirect, lang]);

  useEffect(() => {
    if (router.isReady) {
      const paramsDeleteList: string[] = [];

      // remove invalid redirect (and by extension lang) from query params
      if (router.query[GlobalQueryString.REDIRECT] && redirect === undefined) {
        paramsDeleteList.push(GlobalQueryString.REDIRECT);
      }
      if (router.query[GlobalQueryString.LANG] && lang === undefined) {
        paramsDeleteList.push(GlobalQueryString.LANG);
      }
      if (router.query[GlobalQueryString.IN_APP] && inapp === undefined) {
        paramsDeleteList.push(GlobalQueryString.IN_APP);
      }

      if (paramsDeleteList.length > 0) {
        const newParams = new URLSearchParams(encode(router.query)); // need to encode query which is of type ParsedUrlQuery
        paramsDeleteList.forEach((p) => {
          newParams.delete(p);
        });
        router.replace({ pathname: router.pathname, query: newParams.toString() }, undefined, { shallow: true });
      }
    }
  }, [redirect, lang, inapp, router]);

  return params;
};
